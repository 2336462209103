var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"px-6 py-6",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{staticClass:"mb-6 card-shadow border-radius-xl py-4"},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"2"}},[_c('v-text-field',{attrs:{"append-icon":"fa-search","label":"以完整身份證號搜尋","single-line":"","hide-details":"","clearable":"","disabled":_vm.isLoading},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.pagination.page = 1;
                    _vm.getDataFromApi();}},model:{value:(_vm.searchKeywords.idno),callback:function ($$v) {_vm.$set(_vm.searchKeywords, "idno", $$v)},expression:"searchKeywords.idno"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"2"}},[_c('v-text-field',{attrs:{"append-icon":"fa-search","label":"以電話搜尋","single-line":"","hide-details":"","clearable":"","disabled":_vm.isLoading},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.pagination.page = 1;
                    _vm.getDataFromApi();}},model:{value:(_vm.searchKeywords.phone),callback:function ($$v) {_vm.$set(_vm.searchKeywords, "phone", $$v)},expression:"searchKeywords.phone"}})],1),_c('v-col',{staticClass:"d-flex align-center",class:{
                  'pt-6': !(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm),
                },attrs:{"cols":"12","sm":"4"}},[_c('div',[_c('v-btn',{staticClass:"\n                      font-weight-normal\n                      text-capitalize\n                      btn-primary\n                      bg-gradient-secondary\n                      py-3\n                      px-6\n                      ms-3\n                    ",attrs:{"disabled":_vm.isLoading,"loading":_vm.isLoading},on:{"click":function($event){_vm.pagination.page = 1;
                      _vm.getDataFromApi();}}},[_vm._v(" "+_vm._s(_vm.$t('common["Search"]')))])],1)])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" 會員列表 "),_c('v-spacer')],1),_c('v-card-text',[_c('v-data-table',{ref:"memberTable",attrs:{"headers":_vm.tableHeaders,"items":_vm.tableData,"loading":_vm.isLoading,"loading-text":_vm.$t("common['Loading... Please wait']"),"no-data-text":_vm.$t("common['No Data']"),"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"memberId","show-expand":true,"options":_vm.pagination,"server-items-length":_vm.total,"footer-props":{
                'items-per-page-text': _vm.$t("common['Rows per page']"),
              },"page":_vm.pagination.page,"hide-default-footer":"","items-per-page":_vm.pagination.itemsPerPage,"disable-sort":""},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.pagination=$event},"item-expanded":_vm.loadDetails,"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"page-count":function($event){_vm.pagination.pageCount = $event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
              var headers = ref.headers;
              var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" 市民卡 "),_c('v-btn',{staticClass:"ms-3",attrs:{"outlined":!_vm.$vuetify.breakpoint.xs,"icon":_vm.$vuetify.breakpoint.xs,"x-small":_vm.$vuetify.breakpoint.xs,"color":"blue darken-2","loading":_vm.isRefreshing,"disabled":_vm.isRefreshing},on:{"click":function($event){return _vm.onRefresh(item.memberId)}}},[(_vm.$vuetify.breakpoint.xs)?_c('v-icon',[_vm._v(" fas fa-sync ")]):_c('span',[_c('v-icon',[_vm._v(" fas fa-sync ")]),_vm._v(" 更新市民卡資訊 ")],1)],1)],1),_c('v-list-item-subtitle',[_c('v-data-table',{attrs:{"headers":_vm.tableCardHeaders,"items":_vm.cards,"loading-text":_vm.$t("common['Loading... Please wait']"),"no-data-text":_vm.$t("common['No Data']"),"hide-default-footer":true,"disable-filtering":true,"disable-pagination":true,"disable-sort":true},scopedSlots:_vm._u([{key:"item.cardType",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCardType(item.cardType))+" ")]}},{key:"item.cardStatus",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCardStatus(item.cardStatus))+" ")]}}],null,true)})],1)],1)],1)],1)]}},{key:"item.name",fn:function(ref){
              var item = ref.item;
return [(item.memberId === _vm.editItem.memberId)?_c('v-text-field',{attrs:{"hide-details":true,"dense":"","outlined":"","single-line":""},model:{value:(_vm.editItem.name),callback:function ($$v) {_vm.$set(_vm.editItem, "name", $$v)},expression:"editItem.name"}}):_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item.phone",fn:function(ref){
              var item = ref.item;
return [(
                    item.memberId === _vm.editItem.memberId &&
                    !item.isPhoneVerified
                  )?_c('v-text-field',{attrs:{"hide-details":true,"dense":"","outlined":"","single-line":"","autofocus":true},model:{value:(_vm.editItem.phone),callback:function ($$v) {_vm.$set(_vm.editItem, "phone", $$v)},expression:"editItem.phone"}}):_c('span',[_vm._v(_vm._s(item.phone))])]}},{key:"item.isPhoneVerified",fn:function(ref){
                  var item = ref.item;
return [(item.isPhoneVerified)?_c('v-icon',{attrs:{"color":"green darken-2"}},[_vm._v(" fa-solid fa-check ")]):_vm._e()]}},{key:"item.address",fn:function(ref){
                  var item = ref.item;
return [(item.memberId === _vm.editItem.memberId)?_c('v-text-field',{attrs:{"hide-details":true,"dense":"","single-line":"","outlined":""},model:{value:(_vm.editItem.address),callback:function ($$v) {_vm.$set(_vm.editItem, "address", $$v)},expression:"editItem.address"}}):_c('span',[_vm._v(_vm._s(item.address))])]}},{key:"item.birthday",fn:function(ref){
                  var item = ref.item;
return [(item.memberId === _vm.editItem.memberId)?_c('v-text-field',{attrs:{"hide-details":true,"placeholder":"YYYY-MM-DD","dense":"","single-line":"","outlined":"","autofocus":item.isPhoneVerified},model:{value:(_vm.editItem.birthday),callback:function ($$v) {_vm.$set(_vm.editItem, "birthday", $$v)},expression:"editItem.birthday"}}):_c('span',[_vm._v(_vm._s(item.birthday))])]}},{key:"item.isTyCitizen",fn:function(ref){
                  var item = ref.item;
return [(item.isTyCitizen)?_c('v-icon',{attrs:{"color":"green darken-2"}},[_vm._v(" fa-solid fa-check ")]):_vm._e()]}},{key:"item.hasCityCard",fn:function(ref){
                  var item = ref.item;
return [(item.hasCityCard > 0)?_c('v-icon',{attrs:{"color":"green darken-2"}},[_vm._v(" fa-solid fa-check ")]):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.createdAt ? _vm.moment .unix(item.createdAt) .format("YYYY/MM/DD HH:mm:ss") : ""))])]}},{key:"item.updatedAt",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.updatedAt ? _vm.moment .unix(item.updatedAt) .format("YYYY/MM/DD HH:mm:ss") : ""))])]}},(_vm.canEdit)?{key:"item.actions",fn:function(ref){
                  var item = ref.item;
                  var index = ref.index;
return [(item.memberId === _vm.editItem.memberId)?_c('div',[_c('v-btn',{staticClass:"mx-2 mt-2",attrs:{"dark":"","icon":"","small":"","color":"red"},on:{"click":_vm.onClose}},[_c('v-icon',[_vm._v(" fa-times ")])],1),_c('v-btn',{staticClass:"mx-2 mt-2",attrs:{"dark":"","icon":"","small":"","color":"green"},on:{"click":_vm.onSave}},[_c('v-icon',[_vm._v(" fa-save ")])],1)],1):_c('div',[_c('v-btn',{staticClass:"mx-2 mt-2",attrs:{"dark":"","icon":"","small":"","color":"green"},on:{"click":function($event){return _vm.onEditItem(item, index)}}},[_c('v-icon',[_vm._v(" fa-edit ")])],1),_c('v-btn',{staticClass:"mx-2 mt-2",attrs:{"dark":"","icon":"","small":"","color":"pink darken-2"},on:{"click":function($event){_vm.selectedMemberId = item.memberId;
                      _vm.openDetailDialog = true;}}},[_c('v-icon',[_vm._v(" fa-th-list ")])],1),(_vm.canSendMessage)?_c('v-btn',{staticClass:"mx-2 mt-2",attrs:{"dark":"","icon":"","small":"","color":"orange"},on:{"click":function($event){_vm.selectedMemberId = item.memberId;
                      _vm.onOpenMessageDialogClick();}}},[_c('v-icon',[_vm._v(" far fa-comment-alt ")])],1):_vm._e()],1)]}}:null],null,true)})],1),(_vm.total > 0)?_c('v-card-actions',{staticClass:"card-padding"},[_c('Pagination',{attrs:{"pagination":_vm.pagination,"total":_vm.total,"loading":_vm.isLoading,"showItemsPerPage":"","dataTable":"memberTable"},on:{"update":_vm.getDataFromApi}})],1):_vm._e()],1)],1)],1),_c('v-dialog',{attrs:{"no-click-animation":"","persistent":"","fullscreen":""},model:{value:(_vm.openDetailDialog),callback:function ($$v) {_vm.openDetailDialog=$$v},expression:"openDetailDialog"}},[(_vm.openDetailDialog)?_c('Transactions',{attrs:{"entity-type":"U","member-id":_vm.selectedMemberId},on:{"close":function($event){_vm.openDetailDialog = false}}}):_vm._e()],1),_c('v-dialog',{attrs:{"no-click-animation":"","persistent":"","max-width":"800px"},model:{value:(_vm.openMessageDialog),callback:function ($$v) {_vm.openMessageDialog=$$v},expression:"openMessageDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(" 推播訊息給會員 ")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"editForm",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"outlined":"","label":"訊息內容","rules":_vm.rules.required},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeMessageDialog}},[_vm._v(" "+_vm._s(_vm.$t("common['Close']"))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.onSendMessageClick}},[_vm._v(" "+_vm._s(_vm.$t("common['Save']"))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }