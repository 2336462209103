<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col md="12">
          <v-card class="mb-6 card-shadow border-radius-xl py-4">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="4" md="2">
                  <v-text-field
                    v-model="searchKeywords.idno"
                    append-icon="fa-search"
                    label="以完整身份證號搜尋"
                    single-line
                    hide-details
                    clearable
                    :disabled="isLoading"
                    @keydown.enter="
                      pagination.page = 1;
                      getDataFromApi();
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="2">
                  <v-text-field
                    v-model="searchKeywords.phone"
                    append-icon="fa-search"
                    label="以電話搜尋"
                    single-line
                    hide-details
                    clearable
                    :disabled="isLoading"
                    @keydown.enter="
                      pagination.page = 1;
                      getDataFromApi();
                    "
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  class="d-flex align-center"
                  :class="{
                    'pt-6': !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm),
                  }"
                >
                  <div>
                    <v-btn
                      class="
                        font-weight-normal
                        text-capitalize
                        btn-primary
                        bg-gradient-secondary
                        py-3
                        px-6
                        ms-3
                      "
                      @click="
                        pagination.page = 1;
                        getDataFromApi();
                      "
                      :disabled="isLoading"
                      :loading="isLoading"
                    >
                      {{ $t('common["Search"]') }}</v-btn
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              會員列表
              <v-spacer></v-spacer>
              <!-- 需要後台filter -->
              <!-- <v-text-field
                v-model="search"
                append-icon="fa-search"
                label="搜尋任務名稱/描述"
                single-line
                hide-details
              ></v-text-field> -->
              <!-- <span class="pt-5" v-if="canEdit">
                <v-btn
                  class="
                    font-weight-normal
                    text-capitalize
                    btn-primary
                    bg-gradient-secondary
                    py-3
                    px-6
                    ms-3
                  "
                  :disabled="isLoading"
                  @click="onEditItem(null)"
                >
                  {{ $t(`common["Add"]`) }}
                </v-btn>
              </span> -->
            </v-card-title>
            <v-card-text>
              <v-data-table
                ref="memberTable"
                :headers="tableHeaders"
                :items="tableData"
                :loading="isLoading"
                :loading-text="$t(`common['Loading... Please wait']`)"
                :no-data-text="$t(`common['No Data']`)"
                :single-expand="singleExpand"
                :expanded.sync="expanded"
                item-key="memberId"
                :show-expand="true"
                :options.sync="pagination"
                :server-items-length="total"
                @item-expanded="loadDetails"
                :footer-props="{
                  'items-per-page-text': $t(`common['Rows per page']`),
                }"
                :page.sync="pagination.page"
                hide-default-footer
                @page-count="pagination.pageCount = $event"
                :items-per-page="pagination.itemsPerPage"
                disable-sort
              >
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          市民卡
                          <v-btn
                            class="ms-3"
                            :outlined="!$vuetify.breakpoint.xs"
                            :icon="$vuetify.breakpoint.xs"
                            :x-small="$vuetify.breakpoint.xs"
                            color="blue darken-2"
                            :loading="isRefreshing"
                            :disabled="isRefreshing"
                            @click="onRefresh(item.memberId)"
                          >
                            <v-icon v-if="$vuetify.breakpoint.xs">
                              fas fa-sync
                            </v-icon>

                            <span v-else>
                              <v-icon> fas fa-sync </v-icon> 更新市民卡資訊
                            </span>
                          </v-btn>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <v-data-table
                            :headers="tableCardHeaders"
                            :items="cards"
                            :loading-text="
                              $t(`common['Loading... Please wait']`)
                            "
                            :no-data-text="$t(`common['No Data']`)"
                            :hide-default-footer="true"
                            :disable-filtering="true"
                            :disable-pagination="true"
                            :disable-sort="true"
                          >
                            <template v-slot:item.cardType="{ item }">
                              {{ getCardType(item.cardType) }}
                            </template>
                            <template v-slot:item.cardStatus="{ item }">
                              {{ getCardStatus(item.cardStatus) }}
                            </template>
                          </v-data-table>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </td>
                </template>
                <template v-slot:item.name="{ item }">
                  <v-text-field
                    v-model="editItem.name"
                    :hide-details="true"
                    dense
                    outlined
                    single-line
                    v-if="item.memberId === editItem.memberId"
                  ></v-text-field>
                  <span v-else>{{ item.name }}</span>
                </template>
                <!-- 已驗證過的手機號不可以修改 -->
                <template v-slot:item.phone="{ item }">
                  <!-- <v-text-field
                    v-model="editItem.phone"
                    :hide-details="true"
                    dense
                    outlined
                    single-line
                    :autofocus="true"
                    v-if="
                      item.memberId === editItem.memberId &&
                      !item.isPhoneVerified
                    "
                    @keydown.enter="onSave"
                  ></v-text-field> -->
                  <v-text-field
                    v-model="editItem.phone"
                    :hide-details="true"
                    dense
                    outlined
                    single-line
                    :autofocus="true"
                    v-if="
                      item.memberId === editItem.memberId &&
                      !item.isPhoneVerified
                    "
                  ></v-text-field>
                  <span v-else>{{ item.phone }}</span>
                </template>
                <template v-slot:item.isPhoneVerified="{ item }">
                  <v-icon v-if="item.isPhoneVerified" color="green darken-2">
                    fa-solid fa-check
                  </v-icon>
                </template>
                <template v-slot:item.address="{ item }">
                  <v-text-field
                    v-model="editItem.address"
                    :hide-details="true"
                    dense
                    single-line
                    outlined
                    v-if="item.memberId === editItem.memberId"
                  ></v-text-field>
                  <span v-else>{{ item.address }}</span>
                </template>
                <template v-slot:item.birthday="{ item }">
                  <v-text-field
                    v-model="editItem.birthday"
                    :hide-details="true"
                    placeholder="YYYY-MM-DD"
                    dense
                    single-line
                    outlined
                    :autofocus="item.isPhoneVerified"
                    v-if="item.memberId === editItem.memberId"
                  ></v-text-field>
                  <span v-else>{{ item.birthday }}</span>
                </template>
                <template v-slot:item.isTyCitizen="{ item }">
                  <v-icon v-if="item.isTyCitizen" color="green darken-2">
                    fa-solid fa-check
                  </v-icon>
                </template>
                <template v-slot:item.hasCityCard="{ item }">
                  <v-icon v-if="item.hasCityCard > 0" color="green darken-2">
                    fa-solid fa-check
                  </v-icon>
                </template>
                <template v-slot:item.createdAt="{ item }">
                  <span>{{
                    item.createdAt
                      ? moment
                          .unix(item.createdAt)
                          .format("YYYY/MM/DD HH:mm:ss")
                      : ""
                  }}</span>
                </template>
                <template v-slot:item.updatedAt="{ item }">
                  <span>{{
                    item.updatedAt
                      ? moment
                          .unix(item.updatedAt)
                          .format("YYYY/MM/DD HH:mm:ss")
                      : ""
                  }}</span>
                </template>
                <template v-slot:item.actions="{ item, index }" v-if="canEdit">
                  <div v-if="item.memberId === editItem.memberId">
                    <v-btn
                      class="mx-2 mt-2"
                      dark
                      icon
                      small
                      color="red"
                      @click="onClose"
                    >
                      <v-icon> fa-times </v-icon>
                    </v-btn>

                    <v-btn
                      class="mx-2 mt-2"
                      dark
                      icon
                      small
                      color="green"
                      @click="onSave"
                    >
                      <v-icon> fa-save </v-icon>
                    </v-btn>
                  </div>
                  <div v-else>
                    <v-btn
                      class="mx-2 mt-2"
                      dark
                      icon
                      small
                      color="green"
                      @click="onEditItem(item, index)"
                    >
                      <v-icon> fa-edit </v-icon>
                    </v-btn>
                    <v-btn
                      class="mx-2 mt-2"
                      dark
                      icon
                      small
                      color="pink darken-2"
                      @click="
                        selectedMemberId = item.memberId;
                        openDetailDialog = true;
                      "
                    >
                      <v-icon> fa-th-list </v-icon>
                    </v-btn>
                    <v-btn
                      v-if="canSendMessage"
                      class="mx-2 mt-2"
                      dark
                      icon
                      small
                      color="orange"
                      @click="
                        selectedMemberId = item.memberId;
                        onOpenMessageDialogClick();
                      "
                    >
                      <v-icon> far fa-comment-alt </v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions class="card-padding" v-if="total > 0">
              <Pagination
                :pagination="pagination"
                :total="total"
                :loading="isLoading"
                showItemsPerPage
                dataTable="memberTable"
                @update="getDataFromApi"
              ></Pagination>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog
        v-model="openDetailDialog"
        no-click-animation
        persistent
        fullscreen
      >
        <Transactions
          v-if="openDetailDialog"
          entity-type="U"
          :member-id="selectedMemberId"
          @close="openDetailDialog = false"
        ></Transactions>
      </v-dialog>
      <v-dialog
        v-model="openMessageDialog"
        no-click-animation
        persistent
        max-width="800px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5"> 推播訊息給會員 </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="editForm" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      v-model="message"
                      outlined
                      label="訊息內容"
                      :rules="rules.required"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <!-- <small>*indicates required field</small> -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeMessageDialog">
              {{ $t(`common['Close']`) }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="onSendMessageClick">
              {{ $t(`common['Save']`) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

import { fetchMembers, fetchMemberCards } from "src/apis/fetchData";
import {
  updateMemberInfo,
  refreshMemberInfo,
  sendMessageTo,
} from "src/apis/updateData";
import { formatNumber, checkPermission } from "src/util/utils";
import Pagination from "../Campaigns/Widgets/Pagination.vue";
import variables from "src/util/variables";
import Transactions from "./Widgets/Transactions.vue";

export default {
  name: "Members",
  components: { Transactions, Pagination },
  data() {
    return {
      moment: moment,
      formatNumber: formatNumber,
      searchKeywords: {
        idno: null,
        phone: null,
      },
      isLoading: false,
      singleExpand: true,
      expanded: [],
      total: 0,
      tableData: [],
      tableHeaders: [
        {
          text: "ID",
          sortable: false,
          value: "memberId",
        },
        {
          text: "姓名",
          sortable: false,
          value: "name",
        },
        {
          text: "身份證號",
          sortable: false,
          value: "idno",
        },
        {
          text: "手機號碼",
          sortable: false,
          value: "phone",
        },
        {
          text: "手機驗證",
          sortable: false,
          align: "center",
          value: "isPhoneVerified",
        },
        {
          text: "生日",
          sortable: false,
          value: "birthday",
        },
        {
          text: "地址",
          sortable: false,
          value: "address",
        },
        {
          text: "桃園市民",
          sortable: false,
          align: "center",
          value: "isTyCitizen",
        },
        {
          text: "持有市民卡",
          sortable: false,
          align: "center",
          value: "hasCityCard",
        },
        {
          text: "建立時間",
          sortable: true,
          filterable: false,
          value: "createdAt",
        },
        {
          text: "更新時間",
          sortable: true,
          filterable: false,
          value: "updatedAt",
        },

        { text: "", value: "actions", sortable: false },
      ],
      search: null,
      rules: {
        required: [(v) => !!v || this.$t(`common["Required"]`)],
      },
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
      },
      missions: [],
      debounce: null,
      editItem: {},
      editIndex: -1,
      isLoadingCards: false,
      cards: [],
      tableCardHeaders: [
        {
          text: "卡別",
          sortable: false,
          value: "cardType",
        },
        {
          text: "卡號",
          sortable: false,
          value: "cardNo",
        },
        {
          text: "狀態",
          sortable: false,
          value: "cardStatus",
        },
      ],
      isRefreshing: false,
      openDetailDialog: false,
      selectedMemberId: null,
      openMessageDialog: false,
      message: null,
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
      pointSettings: (state) => state.pointSettings,
      permissions: (state) => state.userPermissions,
      permissionScope: (state) => state.permissionScope,
    }),
    isActionDisabled() {
      return this.isLoading;
    },
    merchantId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.merchant : null;
    },
    canEdit() {
      if (this.permissions && this.permissions.length) {
        return checkPermission(
          this.permissions,
          "Admin:AccountManage",
          "EditMembers",
          true
        );
      }
      return false;
    },
    canSendMessage() {
      if (this.permissions && this.permissions.length) {
        return checkPermission(
          this.permissions,
          "Admin:AccountManage",
          "SendMessage",
          true
        );
      }
      return false;
    },
  },
  watch: {
    // pagination: {
    //   handler() {
    //     this.getDataFromApi();
    //   },
    //   deep: true,
    // },
    // search: {
    //   handler() {
    //     clearTimeout(this.debounce);
    //     let self = this;
    //     this.debounce = setTimeout(function () {
    //       self.getDataFromApi();
    //     }, 200);
    //   },
    // },
  },
  created() {},
  mounted() {},
  methods: {
    init() {},
    getCardType(type) {
      return variables.cardTypes.filter((i) => i.code === type)[0].name;
    },
    getCardStatus(status) {
      return variables.cardStatus.filter((i) => i.code === status)[0].name;
    },
    getDataFromApi() {
      if (!(this.searchKeywords.idno || this.searchKeywords.phone)) {
        this.showAlert("warning", null, "請輪入搜尋條件");
        return;
      }
      this.isLoading = true;
      // reset expanded
      this.expanded = [];
      // this.pagination.pageCount = 0;
      // this.$vuetify.goTo(this.refs.campaignTable);
      this.getMembers()
        .then((data) => {
          this.tableData = [...data.items];
          this.tableData.map((i) => (i.originalStatus = i.reviewStatus));
          this.total = data.total;
          this.isLoading = false;
          // this.$vuetify.goTo(this.refs.campaignTable);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getMembers() {
      this.tableData = [];

      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
        console.log("pagination", this.pagination);

        let query = {
          offset: (page - 1) * itemsPerPage || 0,
          limit: itemsPerPage,
          idno: this.searchKeywords.idno,
          phone: this.searchKeywords.phone,
        };
        let items = [];
        let total = 0;
        // sortby/search 參數是否要丟後端處理
        fetchMembers(this.merchantId, query)
          .then((res) => {
            items = [...res.data.members];
            total = res.data.total;
          })
          .catch((e) => {
            console.log("fetchMembers failed", e);
            this.$swal({
              text: "無法取得會員資料",
              type: "error",
              showCancelButton: false,
              confirmButtonText: this.$i18n.t(`common["Confirm"]`),
              customClass: {
                confirmButton: "btn bg-gradient-success",
                cancelButton: "btn bg-gradient-danger",
              },
            });
          })
          .finally(() => {
            setTimeout(() => {
              resolve({
                items,
                total,
              });
            }, 10);
          });
      });
    },
    onEditItem(item, index) {
      this.editIndex = index;
      this.editItem = Object.assign({}, item);
    },
    onSave() {
      updateMemberInfo(this.merchantId, this.editItem)
        .then((res) => {
          console.log("updateMemberInfo done", res);
          Object.assign(this.tableData[this.editIndex], res.data);
        })
        .catch((e) => {
          console.log("updateMemberInfo failed", e);
          let errmsg = "更新會員資料失敗";
          if (e.response && e.response.data && e.response.data.message) {
            errmsg += "<br/>" + e.response.data.message;
          }
          this.showAlert("error", "更新失敗", errmsg);
        })
        .finally(() => {
          this.editItem = {};
          this.editIndex = -1;
        });
    },
    onRefresh(memberId) {
      this.isRefreshing = true;
      refreshMemberInfo(this.merchantId, memberId)
        .then((res) => {
          console.log("refreshMemberInfo done", res);
          const index = this.tableData.findIndex(
            (i) => i.memberId === memberId
          );
          Object.assign(this.tableData[index], res.data.member);
          this.cards = res.data.cards;
        })
        .catch((e) => {
          console.log("refreshMemberInfo failed", e);
          let errmsg = "更新會員市民卡資料失敗";
          if (e.response && e.response.data && e.response.data.message) {
            errmsg += "<br/>" + e.response.data.message;
          }
          this.showAlert("error", "更新失敗", errmsg);
        })
        .finally(() => {
          this.isRefreshing = false;
        });
    },
    onClose() {
      this.editItem = {};
      this.editIndex = -1;
    },
    loadDetails({ item, value }) {
      // get mission
      if (value) {
        console.log("load detail");
        this.getMemberCards(item.memberId);
      }
    },

    getMemberCards(memberId) {
      this.isLoadingCards = true;
      fetchMemberCards(this.merchantId, memberId)
        .then((res) => {
          this.cards = res.data;
        })
        .catch((e) => {
          console.log("fetchMembers failed", e);
          this.$swal({
            text: "無法取得市民卡資料",
            type: "error",
            showCancelButton: false,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
        })
        .finally(() => {
          this.isLoadingCards = false;
        });
    },
    showAlert(type, title, message) {
      this.$swal({
        title: title,
        html: message,
        type: type,
        showCancelButton: false,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
    },
    onOpenMessageDialogClick() {
      this.message = null;
      this.openMessageDialog = true;
    },
    closeMessageDialog() {
      this.openMessageDialog = false;
      this.message = null;
      this.selectedMemberId = null;
    },
    onSendMessageClick() {
      sendMessageTo(this.merchantId, this.selectedMemberId, {
        message: this.message,
      })
        .then(() => {
          console.log("done send message");
        })
        .catch((e) => {
          console.log("send message to member failed", e);
          this.$swal({
            text: "推播訊息至會員失敗",
            type: "error",
            showCancelButton: false,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
        });
    },
  },
};
</script>
<style scoped>
.text-wrap-whitespace {
  white-space: pre-wrap;
  /* word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word; */
}
</style>
