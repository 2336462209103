var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"blue darken-2"}},[_c('v-toolbar-title',[_vm._v("收支明細")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"fab":"","plain":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v(" fas fa-times")])],1)],1)],1),_c('v-card-title',{staticClass:"justify-center"},[_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-select',{attrs:{"label":_vm.$t("points['Name']"),"items":_vm.availablePoints,"item-text":"name","item-value":"id","required":"","disabled":_vm.isDetailLoading,"no-data-text":_vm.$t("common['No Data']")},model:{value:(_vm.selectedPointId),callback:function ($$v) {_vm.selectedPointId=$$v},expression:"selectedPointId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px","name":"startDate","disabled":_vm.isDetailLoading},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"開始日期","readonly":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},on))]}}]),model:{value:(_vm.showStartPicker),callback:function ($$v) {_vm.showStartPicker=$$v},expression:"showStartPicker"}},[_c('v-date-picker',{attrs:{"color":"blue-grey lighten-1"},on:{"input":function($event){_vm.showStartPicker = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px","name":"endDate","disabled":_vm.isDetailLoading},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"結束日期","readonly":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},on))]}}]),model:{value:(_vm.showEndPicker),callback:function ($$v) {_vm.showEndPicker=$$v},expression:"showEndPicker"}},[_c('v-date-picker',{attrs:{"color":"blue-grey lighten-1"},on:{"input":function($event){_vm.showEndPicker = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1),_c('v-col',{class:_vm.$vuetify.breakpoint.xs ? '' : 'pt-4',attrs:{"cols":"12","sm":"3"}},[_c('v-btn',{staticClass:"\n              font-weight-normal\n              text-capitalize\n              ma-2\n              white--text\n              py-3\n              px-6\n              ms-3\n            ",attrs:{"color":"blue-grey","disabled":_vm.isDetailLoading,"loading":_vm.isDetailLoading},on:{"click":function($event){_vm.pagination.page = 1;
              _vm.getDataFromApi();}}},[_vm._v(" "+_vm._s(_vm.$t("common[\"Search\"]"))+" ")])],1)],1)],1)]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.tableDetailHeaders,"items":_vm.details,"options":_vm.pagination,"server-items-length":_vm.totalDetails,"loading":_vm.isDetailLoading,"loading-text":_vm.$t("common['Loading... Please wait']"),"no-data-text":_vm.$t("common['No Data']"),"no-results-text":_vm.$t("common['No Data']"),"footer-props":{
        'items-per-page-text': _vm.$t("common['Rows per page']"),
      },"page":_vm.pagination.page,"hide-default-footer":"","items-per-page":_vm.pagination.itemsPerPage},on:{"update:options":function($event){_vm.pagination=$event},"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"page-count":function($event){_vm.pagination.pageCount = $event}},scopedSlots:_vm._u([{key:"item.transactionType",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$t(("transactions." + (item.transactionType))))+" ")])]}},{key:"item.income",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.income, 0))+" ")]}},{key:"item.expenditure",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.expenditure, 0))+" ")]}},{key:"item.transactionTs",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.transactionTs ? _vm.moment.unix(item.transactionTs).format("YYYY/MM/DD HH:mm:ss") : ""))])]}}])})],1),_c('v-card-actions',{staticClass:"card-padding"},[_c('Pagination',{attrs:{"pagination":_vm.pagination,"total":_vm.totalDetails,"loading":_vm.isDetailLoading},on:{"update":_vm.getDataFromApi}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }